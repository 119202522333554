import { createI18n } from 'vue-i18n';

const messages = {
  en: require('./translations/en.json'),
  nl: require('./translations/nl.json'),
  fr: require('./translations/fr.json'),
};

const i18n = createI18n({
  locale: 'nl', // Default language
  messages,
});

export default i18n;
