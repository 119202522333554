import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons';

import VueApexCharts from "vue3-apexcharts";
import Uploader from 'vue-media-upload';
import Notifications from '@kyvg/vue3-notification';

import Vue3ConfirmDialog from 'vue3-confirm-dialog';
import "vue3-confirm-dialog/style";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const app = createApp(App);

/* Define global variables */
app.config.globalProperties.$baseURL = process.env.VUE_APP_BASE_URL;
app.config.globalProperties.$baseURLRAW = process.env.VUE_APP_BASE_URL_RAW;
app.config.globalProperties.$token = process.env.VUE_APP_TOKEN;

library.add(fas);

const options = {
    // You can set your default options here
    draggable: false,
    position: 'bottom-right',
};

app.use(Toast, options);

app.use(router);
app.use(Notifications);
app.use(Uploader);
app.use(i18n);
app.use(VueApexCharts);
app.use(Vue3ConfirmDialog);

app.component('vue3-confirm-dialog', Vue3ConfirmDialog.default);
app.component('apex-chart', VueApexCharts);
app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
